import styled from 'styled-components'

export const SelectMethodStyled = styled.div`
  .row {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.9rem;

    margin-top: 2rem;
    :first-of-type {
      margin-top: 0;
    }

    .label {
      color: #85a0ad;
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }
  }
`

export const SelectMethodEmpty = styled.div`
  color: #808080;
  font-style: normal;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
`

export const SelectMethodTitle = styled.div`
  color: #000000;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2rem;

  margin-bottom: 2rem;
`

export const SelectMethodContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  &.use-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
  }
`
