import { Preloader } from 'mmfintech-portal-commons'
import { PreloaderWrapper } from './SuspenseFallback.styled'

export const SuspenseFallback = () => {
  return (
    <PreloaderWrapper>
      <Preloader />
    </PreloaderWrapper>
  )
}
