import { ReactNode, useContext } from 'react'

import { ThemeContext } from 'styled-components'
import { CheckoutHeader, CheckoutWrapper } from './CheckoutContainer.styled'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { actions, useSessionId } from 'mmfintech-checkout-commons'
import { LanguageMenu } from '../LanguageMenu'

type CheckoutContainerProps = {
  className?: string
  children?: ReactNode
  hideLanguage?: boolean
}

export const CheckoutContainer = ({ className, children , hideLanguage = false }: CheckoutContainerProps) => {
  const themeContext = useContext(ThemeContext)

  const { sessionId } = useSessionId()

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const handleLanguageChanged = (languageCode: string): void => {
    void dispatch(actions.common.storeSelectedLanguage(sessionId, languageCode))
  }


  return (
    <CheckoutWrapper className={className}>
      <CheckoutHeader>
        <span>
          <img src={themeContext.brandLogo} alt='Logo' />
        </span>
        {hideLanguage ? null : <LanguageMenu onChange={handleLanguageChanged} />}
      </CheckoutHeader>

      {children}
    </CheckoutWrapper>
  )
}
