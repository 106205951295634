import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { DepositResult } from '../DepositResult'
import { ThunkDispatch } from 'redux-thunk'
import { CancelContainer, PaymentContainer } from './Checkout.styled'
import { WalletCheckout, WalletCheckoutOtp } from '../WalletCheckout'
import { Button, ErrorDisplay, Input, Preloader } from 'mmfintech-portal-commons'
import { CheckoutContainer, CheckoutContent, MerchantPane, SelectMethod } from '../../components'

// import { useStoragePermissions } from './utility'
import { isEmptyString, isValidArray, isValidString, tr, translateCountryName } from 'mmfintech-commons'
import { actions, CheckoutFlowStep, useCheckout, useSessionId, useUnloadBeacon } from 'mmfintech-checkout-commons'

import {
  CheckoutMethodField,
  CountryChoice,
  PaymentMethodEnum,
  PaymentOption,
  PaymentSessionStatusEnum,
  SupportedBank
} from 'mmfintech-commons-types'

const Checkout = () => {
  const { sessionId } = useSessionId()

  const {
    logo,
    banks,
    banksCount,
    checkoutCurrencies,
    checkoutCountries,
    checkoutCountriesError,
    checkoutError,
    checkoutOptions,
    checkoutOptionsFetching,
    checkoutPay,
    checkoutPayFetching,
    session,
    sessionFetching
  } = useSelector(
    ({
      checkout: {
        logo,
        banks,
        banksCount,
        checkoutCurrencies,
        checkoutCountries,
        checkoutCountriesError,
        checkoutError,
        checkoutOptions,
        checkoutOptionsFetching,
        checkoutPay,
        checkoutPayFetching,
        session,
        sessionFetching
      }
    }) => ({
      logo,
      banks,
      banksCount,
      checkoutCurrencies,
      checkoutCountries,
      checkoutCountriesError,
      checkoutError,
      checkoutOptions,
      checkoutOptionsFetching,
      checkoutPay,
      checkoutPayFetching,
      session,
      sessionFetching
    }),
    shallowEqual
  )

  const [storageAccess, setStorageAccess] = useState(false)

  const [triggered, setTriggered] = useState(false)

  const history = useHistory()
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const { sessionStatus, paymentResult, paymentResultType, processingAmount, processingCurrency, showCancelButton } =
    session || {}
  useUnloadBeacon({ sessionId })

  const {
    step,
    fields,
    status,
    logEvent,
    formValues,
    countryCode,
    setCountryCode,
    paymentMethod,
    setPaymentMethod,
    hasBankSelection,
    handleStartOver,
    handlePay,
    handlePayWithWallet,
    jetonWalletSpecialCase,
    handlePaymentStatusResponse,
    initialized
  } = useCheckout({
    enableLogging: true,
    enableJetonWalletStep: true,
    preselectSingleMethod: true,
    initialStep: CheckoutFlowStep.SELECT_METHOD
  })

  // const permission = useStoragePermissions()

  const handleCancelClick = () => {
    if (sessionId) {
      void dispatch(actions.checkout.cancelSessionPayment(sessionId))
    }
    logEvent('cancelled_by_payer')
    history.push('/fail')
    return false
  }

  const handleBankSelected = (code: string) => {
    logEvent('bank_selected', code)
  }

  const parseResult = () => {
    try {
      return JSON.parse(paymentResult)
    } catch (error) {
      return paymentResult
    }
  }

  const prepareResponse = () =>
    sessionStatus?.value === PaymentSessionStatusEnum.IN_PROGRESS
      ? { result: parseResult(), resultType: paymentResultType, processingAmount, processingCurrency }
      : checkoutPay

  const validBankSelection = () => {
    const exists = hasBankSelection()
    return !exists || (exists && banksCount === 1 && isValidString(formValues.getValue('bankChoiceId')))
  }

  const validFields = () => {
    if (paymentMethod === PaymentMethodEnum.JETON_WALLET) return true

    const option = checkoutOptions?.find((v: PaymentOption) => v.paymentMethod === paymentMethod)
    if (option) {
      const { fields } = option
      if (Array.isArray(fields)) {
        const find = fields.find((item: CheckoutMethodField) => {
          const { name, prefilledValue, displayIfPrefilled } = item
          return (!prefilledValue?.length || displayIfPrefilled) && name !== 'bankChoiceId'
        })
        return !find
      }
    }
    return true
  }

  useEffect(() => {
    if (typeof document.hasStorageAccess === 'function') {
      document.hasStorageAccess().then(response => setStorageAccess(response))
    } else {
      setStorageAccess(true)
    }
  }, [])

  useEffect(() => {
    if (
      initialized &&
      !triggered &&
      !checkoutPayFetching &&
      isValidString(paymentMethod) &&
      validBankSelection() &&
      validFields()
    ) {
      setTriggered(true)
    }
    // eslint-disable-next-line
  }, [initialized, paymentMethod, formValues.getValue('bankChoiceId')])

  useEffect(() => {
    if (triggered) {
      handlePay()
    }
  }, [triggered])

  // useEffect(() => {
  //   if (step === CheckoutFlowStep.JETON_WALLET) {
  //     if (permission.needPermission && permission.haveCheckedPermission) {
  //       permission.askForPermission()
  //     }
  //   }
  // }, [step, permission.haveCheckedPermission])

  return (
    <CheckoutContainer>
      <CheckoutContent>
        <ErrorDisplay error={checkoutCountriesError} />

        {sessionFetching || !initialized ? (
          <PaymentContainer>
            <Preloader />
          </PaymentContainer>
        ) : (
          <>
            {step !== CheckoutFlowStep.JETON_WALLET && <MerchantPane session={session} logo={logo} />}

            {(step === CheckoutFlowStep.ADDITIONAL_FIELDS ||
              step === CheckoutFlowStep.SELECT_BANK ||
              step === CheckoutFlowStep.SELECT_METHOD) && (
              <>
                <PaymentContainer>
                  {status === 'IDLE' && (
                    <>
                      {checkoutCountries?.length > 1 ? (
                        <div className='mb-3'>
                          <Input
                            type='select'
                            name='countryCode'
                            label={tr('CHECKOUT.PAYMENT.COUNTRY', 'Country')}
                            placeholder={tr('CHECKOUT.PAYMENT.COUNTRY_PLACEHOLDER', 'Select country')}
                            options={checkoutCountries?.map((country: CountryChoice) => ({
                              value: country.countryCode,
                              label: country.name
                            }))}
                            value={countryCode}
                            onChange={(_name: string, value: string) => setCountryCode(value)}
                            hideErrorLine
                            required
                          />
                        </div>
                      ) : (
                        <div className='mb-3'>
                          <Input
                            type='static'
                            label={tr('CHECKOUT.PAYMENT.COUNTRY', 'Country')}
                            value={translateCountryName(countryCode)}
                            hideErrorLine
                          />
                        </div>
                      )}

                      <SelectMethod
                        className='use-grid'
                        method={paymentMethod}
                        setMethod={setPaymentMethod}
                        methodsLoading={checkoutOptionsFetching}
                        countryCode={countryCode}
                        special={jetonWalletSpecialCase}
                      />

                      {isValidArray(fields) && paymentMethod !== PaymentMethodEnum.JETON_WALLET ? (
                        <>
                          <div className='mt-2' />

                          {fields.map((item, index) => {
                            const { name, type, defaultLabel, translationKey, required, sensitive } = item

                            if (name === 'bankChoiceId') {
                              return (
                                <Input
                                  key={index}
                                  type='select'
                                  label={tr('CHECKOUT.PAYMENT.BANK', 'Bank')}
                                  {...formValues.registerInput(name, handleBankSelected)}
                                  options={[
                                    ...(jetonWalletSpecialCase
                                      ? [
                                          {
                                            value: PaymentMethodEnum.JETON_WALLET,
                                            label: tr('METADATA.PAYMENT_OPTIONS.JETON_WALLET', 'Jeton Wallet')
                                          }
                                        ]
                                      : []),
                                    ...(Array.isArray(banks)
                                      ? banks?.map((bank: SupportedBank) => {
                                          const { bankChoiceId, name } = bank
                                          return { value: bankChoiceId, label: name }
                                        })
                                      : [])
                                  ]}
                                  required={required}
                                  hideErrorLine
                                />
                              )
                            }

                            if (name === 'currency') {
                              return (
                                <Input
                                  key={index}
                                  type='select'
                                  label={tr(translationKey, defaultLabel)}
                                  {...formValues.registerInput(name)}
                                  options={checkoutCurrencies?.map((currency: string) => {
                                    return { value: currency, label: currency }
                                  })}
                                  required={required}
                                  hideErrorLine
                                />
                              )
                            }

                            if (name === 'documentId' && paymentMethod === 'PIX') {
                              return (
                                <Input
                                  key={index}
                                  type={type || 'text'}
                                  label={tr(translationKey, defaultLabel)}
                                  {...formValues.registerShort(name)}
                                  onChange={(name: string, value: string) =>
                                    formValues.setValue(name, value.replace(/[ ./-]/g, ''))
                                  }
                                  required={required}
                                  hideErrorLine
                                  autoComplete='off'
                                />
                              )
                            }

                            return (
                              <Input
                                key={index}
                                type={sensitive ? 'password' : type || 'text'}
                                label={tr(translationKey, defaultLabel)}
                                {...formValues.registerInput(name)}
                                required={required}
                                hideErrorLine
                                autoComplete='off'
                              />
                            )
                          })}
                        </>
                      ) : null}
                    </>
                  )}

                  <ErrorDisplay error={checkoutError} />

                  {status === 'IDLE' && (
                    <div className='buttons'>
                      <Button
                        type='button'
                        color='primary'
                        text={tr('CHECKOUT.PAYMENT.BUTTON_PAY', 'Pay')}
                        onClick={handlePay}
                        disabled={isEmptyString(paymentMethod)}
                        loading={checkoutPayFetching}
                        data-test='button-pay'
                      />
                    </div>
                  )}
                </PaymentContainer>

                {showCancelButton ? (
                  <CancelContainer>
                    <Link to='#' onClick={handleCancelClick}>
                      {tr('CHECKOUT.BUTTONS.CANCEL', 'Cancel')}
                    </Link>
                  </CancelContainer>
                ) : null}
              </>
            )}

            {step === CheckoutFlowStep.JETON_WALLET && (
              <WalletCheckout
                sessionId={sessionId}
                onCancel={handleStartOver}
                handlePay={handlePayWithWallet}
                paymentMethod={paymentMethod}
                hasStorageAccess={storageAccess}
                setStorageAccess={setStorageAccess}
              />
            )}

            {step === CheckoutFlowStep.WALLET_OTP && (
              <WalletCheckoutOtp onSuccess={response => handlePaymentStatusResponse(response)} wallet={true} />
            )}

            {step === CheckoutFlowStep.DEPOSIT_RESULT && (
              <DepositResult session={session} sessionId={sessionId} response={prepareResponse()} logEvent={logEvent} />
            )}
          </>
        )}
      </CheckoutContent>
    </CheckoutContainer>
  )
}

export default Checkout
