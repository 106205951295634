import styled from 'styled-components'

export const CheckoutWrapper = styled.div`
  min-height: 100vh;
  width: 100vw;
  padding: 1rem 1rem 3rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  background-image: linear-gradient(120deg, #ee834e, #d25286);
  background-repeat: repeat-y;
  background-position: center top;
  background-size: cover;

  .err-msg-wrapper {
    justify-content: center;
  }
`

export const CheckoutHeader = styled.div`
  width: 100%;
  max-width: 50rem;
  padding: 2rem 0 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
