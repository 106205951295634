import { ReactNode } from 'react'

type DataRowProps = {
  label?: ReactNode
  value?: string
}

export const DataRow = ({ label, value }: DataRowProps) =>
  value?.length ? (
    <div className='row'>
      <div className='label'>{label}</div>
      <div className='value'>{value}</div>
    </div>
  ) : null
