import styled from "styled-components";

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  .text {
    margin-top: 2px;
    font-size: 16.5px;
    font-weight: 405;
    line-height: 24.76px;
    letter-spacing: 0.03em;

    .title {
      color: rgba(54, 8, 2, 0.5);
      text-transform: capitalize;
    }
    .content{
        text-transform: uppercase;
    }
  }
`