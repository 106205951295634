import { cryptoCurrencyNotes, tr } from 'mmfintech-commons'

import { WarningMessageTitle, WarningMessageWrapper } from './WarningMessage.styled'

import InfoIcon from '../../assets/icons/info-circle.svg'

export const WarningMessage = ({ currency }) => {
  const notes = cryptoCurrencyNotes(currency)

  if (notes) {
    return (
      <WarningMessageWrapper>
        <WarningMessageTitle>
          <img src={InfoIcon} alt='' />
          <span>{tr('CHECKOUT.WARNING_MESSAGE.PLEASE_NOTE', 'Please note')}</span>
        </WarningMessageTitle>

        {notes.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </WarningMessageWrapper>
    )
  }

  return null
}
