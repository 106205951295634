import { useState } from 'react'

import { MethodButton } from '../../components/SelectMethod/MethodButton'
import { PaymentContainer } from '../CheckoutPayment/Checkout.styled'
import { CheckoutContainer, CheckoutContent } from '../../components'

import { PaymentMethodEnum } from 'mmfintech-commons-types'
import { SelectMethodContainer, SelectMethodStyled } from '../../components/SelectMethod/SelectMethod.styled'

const TestMethods = () => {
  const [selected, setSelected] = useState<string>(null)

  return (
    <CheckoutContainer>
      <CheckoutContent>
        <h2>Testing method names and logos</h2>

        <PaymentContainer>
          <SelectMethodStyled>
            <SelectMethodContainer className='use-grid'>
              {Object.keys(PaymentMethodEnum)
                .sort()
                .map(name => {
                  return (
                    <MethodButton
                      key={name}
                      name={name}
                      selected={selected === name}
                      setSelected={name => {
                        setSelected(name)
                        console.log('selected:', name)
                      }}
                    />
                  )
                })}
            </SelectMethodContainer>
          </SelectMethodStyled>
        </PaymentContainer>
      </CheckoutContent>
    </CheckoutContainer>
  )
}

export default TestMethods
