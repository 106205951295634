import styled from 'styled-components'

import { configuration } from 'mmfintech-checkout-commons'
import { getPaymentMethodImageUrl } from 'mmfintech-commons'

export const MethodImage = ({ method, className = undefined }) => (
  <ImageWrapper className={className}>
    <img src={`${configuration.readBackendConfig()}${getPaymentMethodImageUrl(method)}`} alt='' />
  </ImageWrapper>
)

const ImageWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 0 0.5rem;

  width: 10rem;
  height: 10rem;
  border: 1px solid #d9d9d9;
  border-radius: 5px;

  &.selected {
    //background-color: #fee9e6;
    border: 2px solid #fba498;
  }

  & > img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;

    &.local {
      width: 80%;
      border-radius: 0;
    }
  }
`
