import { tr } from 'mmfintech-commons'

import { MethodImage } from '../MethodButton/MethodImage'
import { MethodPaneWrapper } from './MethodPane.styled'

type MethodPaneProps = {
  method: string
}

export const MethodPane = ({ method }: MethodPaneProps) =>
  method ? (
    <MethodPaneWrapper>
      <span style={{ fontWeight: 500 }}>{tr('METADATA.PAYMENT_OPTIONS.' + method, method)}</span>
      <MethodImage method={method} />
    </MethodPaneWrapper>
  ) : null
