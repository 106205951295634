import styled from 'styled-components'

export const SelectAccountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`

export const SelectAccountStyled = styled.div`
  width: 100%;
  position: relative;
`

export const AccountListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  border-radius: 0 0 12px 12px;

  overflow-y: auto;
  max-height: 26rem;

  color: #85a0ad;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`

export const AccountStyled = styled.div`
  width: 100%;
  height: 116px;
  border-radius: 12px;
  cursor: pointer;
  background: rgba(247, 59, 32, 0.05);
  border: 1px solid transparent;
  display: flex;
  flex-flow: row nowrap;
  flex-direction: column;

  :hover {
    background: #fee9e6;
  }

  &.disabled {
    pointer-events: none;
  }

  &.selected {
    border: 1px solid rgba(247, 59, 32, 1);
  }

  .icon-wrapper {
    display: flex;
    text-align: center;
    border-radius: 50px;
    width: 80px;
    height: 36px;
    margin-right: 1rem;
    gap: 0.4rem;
    p {
      display: flex;
      justify-content: center;
      color: rgba(54, 8, 2, 1);
      font-size: 16px;
      font-weight: 410;
      letter-spacing: 0.03em;
      text-align: left;
      align-items: center;
    }
    background-color: rgba(247, 59, 32, 0.05);
    span {
      border-radius: 50%;
    }
  }

  .icon-all {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background: rgba(247, 59, 32, 1);
    border-radius: 4px;
    color: #ffffff;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.2rem;
  }

  .content {
    display: flex;
    flex-flow: column nowrap;
    padding: 18px;
    width: 100%;
    height: 116px;
    flex-direction: row;
    justify-content: space-between;

    .balance-label {
      font-size: 16px;
      margin-top: 10px;
    }

    .balance {
      color: #000000;
      font-weight: 500;
      b {
        font-weight: 700;
      }
    }
  }
  .iban-label {
    font-size: 16px;
    font-weight: 405;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: left;
    margin: 0 0 16px 16px;
    span {
      color: rgba(54, 8, 2, 1);
      padding-left: 0.4rem;
    }
  }
  .iban-content-wrapper {
    margin-bottom: 1.6rem;
  }
`

export const SelectAccountError = styled.div`
  color: #ff3131;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  margin-top: 5px;
  margin-left: 2.5rem;
`
