import styled from 'styled-components'

export const CurrencyIconWrapper = styled.span<{ size: string }>`
  display: inline-block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  border: 1px solid rgb(254, 233, 230);
  border-radius: 50%;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`
