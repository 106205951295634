import styled from 'styled-components'

export const MethodPaneWrapper = styled.div`
  background: transparent;
  border: 1px solid rgba(254, 233, 230, 1);
  border-radius: 16px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.3rem 2rem;

  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;

  .icon {
    width: 3.5rem;
    height: 3.5rem;
    background-size: contain;
    border: none;
  }
`
