import { ReactNode, useEffect, useState } from 'react'

import cn from 'classnames'
import { getCurrencySymbol, isValidArray, isValidString, tr } from 'mmfintech-commons'

import { CurrencyIcon } from '../CurrencyIcon'
import {
  AccountListStyled,
  AccountStyled,
  SelectAccountError,
  SelectAccountStyled,
  SelectAccountWrapper
} from './SelectAccount.styled'

import { AccountBalanceOut } from 'mmfintech-commons-types'

type SelectAccountProps = {
  staticDisplay?: boolean
  accounts?: AccountBalanceOut[]
  error?: string
  label?: ReactNode
  selectedAccount?: AccountBalanceOut
  setSelectedAccount?: (account: AccountBalanceOut) => void
  noShadow?: boolean
  hasExchangeAssets?: boolean
  className?: string
  showId?: boolean
  noBorder?: boolean
}

export const SelectAccount = (props: SelectAccountProps) => {
  const {
    staticDisplay = false, // if set, static account display will be presented
    accounts, // list of accounts to select from
    error, // error message (if needed)
    selectedAccount, // selected account
    setSelectedAccount, // selected account setter
    hasExchangeAssets = true,
    className
  } = props

  const [preselectAccountId, setPreselectAccountId] = useState(0)

  const handleSelect = (account: AccountBalanceOut) => {
    setSelectedAccount(account)
  }

  useEffect(() => {
    if (preselectAccountId) {
      const find = accounts.find(account => account.id === preselectAccountId)
      setPreselectAccountId(0)
      handleSelect(find)
    }
    // eslint-disable-next-line
  }, [accounts])

  return (
    <SelectAccountWrapper className={cn('select-account-wrapper', className)} data-test='account-dropdown'>
      {/* {label && <SelectAccountLabel>{label}</SelectAccountLabel>} */}
      <SelectAccountStyled>
        {!staticDisplay && (
          <AccountListStyled data-test='accounts-menu-content'>
            {!isValidArray(accounts) && (
              <AccountStyled data-test='all-accounts-menu-item' className='disabled'>
                <span className='icon'>
                  <span className='icon-all'>N/A</span>
                </span>
                <span className='content'>
                  <span className='balance'>
                    <b>
                      {hasExchangeAssets
                        ? tr('CHECKOUT.COMPONENTS.SELECT_ACCOUNT.NO_ACCOUNTS', 'No Eligible Accounts')
                        : tr(
                            'CHECKOUT.COMPONENTS.SELECT_ACCOUNT.CANNOT_EXCHANGE',
                            'Currently this asset cannot be exchanged'
                          )}
                    </b>
                  </span>
                </span>
              </AccountStyled>
            )}
            {accounts?.map(account => {
              const { id, currencyCode, balance, dedicatedMerchantBankAccounts } = account
              const { iban } = dedicatedMerchantBankAccounts[0] || {}

              return (
                <AccountStyled
                  key={id}
                  onClick={() => handleSelect(account)}
                  data-test='account-menu-item'
                  className={cn('option', { selected: id === selectedAccount?.id })}>
                  <div className='content'>
                    <span className='icon-wrapper'>
                      <CurrencyIcon currency={currencyCode} size='36px' />
                      <p>{currencyCode}</p>
                    </span>
                    <span className='balance-label'>
                      {tr('CHECKOUT.COMPONENTS.SELECT_ACCOUNT.BALANCE_LABEL', 'Balance')}:{' '}
                      <span className='balance'>
                        {getCurrencySymbol(currencyCode)}
                        {balance}
                      </span>
                    </span>
                  </div>
                  <div className='iban-content-wrapper'>
                    {iban && (
                      <span className='iban-label'>
                        {tr('CHECKOUT.COMPONENTS.IBAN_LABEL', 'IBAN')}
                        <span className='iban-content'>{iban}</span>
                      </span>
                    )}
                  </div>
                </AccountStyled>
              )
            })}
          </AccountListStyled>
        )}
      </SelectAccountStyled>

      {isValidString(error) && <SelectAccountError>{error}</SelectAccountError>}
    </SelectAccountWrapper>
  )
}
