import styled from 'styled-components'

export const StyledForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;

  .input-wrapper,
  .button {
    width: 100%;
  }

  .login-title {
    color: #ff3131;
    font-weight: 700;
    font-size: 3.2rem;
    margin-bottom: 3rem;
  }

  .account-title {
    font-size: 24px;
    font-weight: 405;
    line-height: 36px;
    text-align: left;
    color: rgba(54, 8, 2, 0.5);
    margin-bottom: 3rem;
  }
`
