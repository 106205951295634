import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { StyledForm } from '../WalletCheckout.styled'
import { ThunkDispatch } from 'redux-thunk'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { PaymentContainer, SignupLinkWrapper } from '../../CheckoutPayment/Checkout.styled'

import { tr, useFormValues } from 'mmfintech-commons'
import { actions, configuration } from 'mmfintech-checkout-commons'

export const WalletCheckoutLogin = ({ sessionId, onCancel, onSuccess }) => {
  const { loginError, loginFetching } = useSelector(
    ({ auth: { loginError, loginFetching } }) => ({ loginError, loginFetching }),
    shallowEqual
  )

  const [error, setError] = useState<string>(null)
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  // const inFrame = typeof window !== 'undefined' && window.location !== window.parent.location

  const loginValues = useFormValues({
    email: { required: true, validation: 'email' },
    password: { required: true }
  })

  const actualLogin = () => {
    void dispatch(actions.auth.login(sessionId, loginValues.prepare(), onSuccess))
  }

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    setError(null)
    e.preventDefault()
    if (loginValues.areValid()) {
      actualLogin()
    }
  }

  const preparePortalLink = (path: string): string => {
    const host = configuration.readBackendConfig().replace('pay', 'portal')
    return `${host}${path.substring(0, 1) === '/' ? '' : '/'}${path}`
  }

  useEffect(() => {
    return () => {
      setError(null)
    }
  }, [])

  return (
    <>
      <PaymentContainer data-test='wallet-checkout-login'>
        <StyledForm noValidate onSubmit={handleLogin}>
          <div className='login-title'>{tr('CHECKOUT.LOGIN.SIGN_IN', 'Sign In')}</div>

          <Input
            id='email'
            type='email'
            data-test='email'
            label={tr('CHECKOUT.LOGIN.EMAIL', 'Email address')}
            {...loginValues.registerInput('email')}
            disabled={loginFetching}
            hideErrorLine
            autoFocus
          />

          <Input
            id='password'
            type='password'
            data-test='password'
            label={tr('CHECKOUT.LOGIN.PASSWORD', 'Password')}
            {...loginValues.registerInput('password')}
            disabled={loginFetching}
            autoComplete='off'
            hideErrorLine
          />

          <div className='link-wrap' data-test='forgot-password'>
            <a href={preparePortalLink('?forgotPassword')} target='_blank' rel='noopener noreferrer'>
              {tr('CHECKOUT.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}
            </a>
          </div>

          <ErrorDisplay error={[loginError, error]} />

          <Button
            type='submit'
            color='alternative'
            className='mb-1'
            text={tr('CHECKOUT.LOGIN.BUTTON_LOGIN', 'Log in')}
            loading={loginFetching}
            data-test='button-submit'
          />
          <Button
            type='button'
            color='secondary'
            text={tr('CHECKOUT.LOGIN.BUTTON_BACK', 'Back')}
            disabled={loginFetching}
            onClick={onCancel}
            data-test='button-back'
          />
        </StyledForm>
      </PaymentContainer>

      <SignupLinkWrapper>
        {tr('CHECKOUT.LOGIN.NO_ACCOUNT', "Don't have an account?")}
        <a href={preparePortalLink('signup')} data-test='sign-up' target='_blank' rel='noopener noreferrer'>
          <span>{tr('CHECKOUT.LOGIN.SIGN_UP', 'Sign up')} →</span>
        </a>
      </SignupLinkWrapper>
    </>
  )
}
