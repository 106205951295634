import styled from 'styled-components'

export const MerchantPaneWrapper = styled.div`
  width: 100%;
  border-radius: 2 rem;
  color: #000000;

  .left {
    /* background: rgba(163, 184, 194, 0.35); */
    padding: 2.2rem 5rem;
    font-size: 2rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2.4rem;

    .value {
      font-size: 3.2rem;
      font-weight: 600;
      line-height: 3.9rem;
      margin-top: 0;
    }
  }

  .right {
    background: #ffffff;
    padding: 2rem 5rem 4rem;

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    font-size: 1.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.9rem;

    .row {
      margin-top: 2rem;
      :first-of-type {
        margin-top: 0;
      }
    }

    .label {
      color: #85a0ad;
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }
  }
`

export const MerchantPaneAmountContainer = styled.div`
  background: #fee9e6;
  border-radius: 2rem 2rem 0 0;
  padding: 2.2rem 5rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.4rem;

  .value {
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 3.9rem;
    margin-top: 0;
  }
`

export const MerchantPaneNetAmountContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  background: rgba(163, 184, 194, 0.6);
  padding: 0 5rem;
  height: 5rem;

  color: #000000;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  .value {
    color: #000000;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.9rem;
  }
`

export const MerchantPaneInfoContainer = styled.div`
  background: #ffffff;
  border-radius: 0 0 2rem 2rem;
  padding: 2rem 5rem 4rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.9rem;

  .row {
    margin-top: 2rem;
    :first-of-type {
      margin-top: 0;
    }
  }

  .label {
    color: #85a0ad;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  .subtitle {
    color: #000000;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
    margin-bottom: 1.5rem;
  }
  .preview-groupe-label { 
    color: rgba(54, 8, 2, 0.5);
    font-size: 20px;
    line-height :20px
  }
  .preview-detail-information-wrapper { 
    padding : 0.4rem 0 0 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .preview-detail-container{ 
    margin-top: 1.2rem;
  }
`

export const MerchantPaneLogoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .logo {
    max-height: 8rem;
    max-width: 20rem;
    margin-right: -2rem;
  }

  .fee {
    color: #678899;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 15px;
  }
`
