import styled from 'styled-components'

export const MenuWrapper = styled.div`
  color: #454a54;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  background-color: rgba(255, 246, 245, 1);
  border-radius: 8px;
  width: 10rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.opened {
    background-color: rgb(254, 235, 233) ;
    border-radius: 8px 8px 0 0;
  }
`

export const MenuContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 5px;
   
  .arrow {
    margin: 0 0 0 5px;
    width: 8px;
  }
`

export const MenuDropDown = styled.div`
  z-index: 1001;
  position: absolute;

  top: 3rem;
  right: 0;

  width: 10rem;
  white-space: nowrap;
 
  
  background: rgba(255, 246, 245, 1);
  border-radius: 8px;
  box-shadow: rgba(172, 182, 191, 0.25) -1px 3px 10px;

  display: none;
  &.opened {
    display: block;
    border-radius: 0 0 8px 8px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      cursor: pointer;
      display: flex; 
      align-items: center;
      justify-content: center;

      height: 3rem;
      color: #454a54;
      padding: 0 2rem;

      :hover {
        border-radius: 8px;
        background: rgb(254, 235, 233);
      }
    }
  }
`
